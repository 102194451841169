import Vue from 'vue'
import App from './App.vue'
import router from '@/router/routers' // 引入路由配置
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import VueDeviceDetector from 'vue-device-detector' // 识别设备是否为移动端
import store from './store/index' // 引入vuex
// import VueSocketIO from 'vue-socket.io'
// import io from 'socket.io-client'

Vue.config.productionTip = false
Vue.use(ElementUI)
// Vue.use(
//   new VueSocketIO({
//     debug: false,
//     // connection: io('https://www.metavillage8.com'), // 生产环境配置
//     connection: io('https://www.xn--z4qp15c.com'), // 生产环境配置
//     // connection: io('http://127.0.0.1:8001'), // 开发环境配置
//     options: { // Optional options,
//       autoConnect: false // 关闭自动连接，在用户登录后在连接。
//       // withCredentials: true // 确保设置为true
//     },
//     allowEIO3: true,
//     vuex: {
//       store,
//       actionPrefix: 'SOCKET_',
//       mutationPrefix: 'SOCKET_'
//     }
//
//   })
// )

Vue.use(VueDeviceDetector)
// 创建事件总线
Vue.prototype.$bus = new Vue()

new Vue({
  render: h => h(App),
  router, // 使用路由配置
  store,
  // 这里为全局监听socket事件消息，监听函数这里只写了一点，其实很有很多事件。
  sockets: {
    connecting() {
      console.log('正在连接')
    },
    disconnect() {
      console.log('Socket 断开')
    },
    connect_failed() {
      console.log('连接失败')
    },
    connect() {
      console.log('socket connected')
    }
  }
}).$mount('#app')
